<template>
    <div class="app-container test-tips">
      <h4 class="title" v-if="$route.query.testType==='1'">MBTI职业性格测评</h4>
      <h4 class="title" v-else-if="$route.query.testType==='2'">性格与选科选专业</h4>
      <h4 class="title" v-else-if="$route.query.testType==='3'">中学生人格测评</h4>
      <div class="main">
        <h4>温馨提示:</h4>
        <ul class="tips-main">
          <li>1、问卷选项没有“对”与“错”之分。请选择你是怎样做的，而不是选择你认为哪样更好。</li>
          <li>2、测试的目的是反映最真实的自己。请最大程度放松下来，选择更接近你平时的感受或行为的那项。</li>
          <li>3、共{{questionNumber}}题，约10分钟可以做完。</li>
        </ul>
        <div class="start">
          <el-button class="tips-btn" type="primary" @click="startTest">明白了，开始吧</el-button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    export default {
      name: "test-tips",
      data(){
        return{}
  
      },
      props: {
        questionNumber: {
            type: Number,
            default: 60
        }
      },
  
      methods:{
        startTest(){
          this.$emit('change', true)
          // this.$router.push('/test-content?testType='+this.$route.query.testType)
        }
      }
    }
  </script>
  
  <style scoped lang="less">
    
    .test-tips{
      padding: 2rem 0;
      .title{
        text-align: center;
        font-size: 24px;
        color: rgba(var(--themecolor),0.75);
      }
      .main{
        border: 1px solid #e0e0e0;
        padding: 3rem;
        .tips-main{
  
          li{
            margin: 1rem 0;
          }
        }
  
        .start{
          padding: 2rem 0;
          .tips-btn{
            width: 8rem;
            text-align: center;
          }
        }
  
  
      }
    }
  </style>
  <style lang="less">
  .start .el-button--primary:hover{
    background: rgba(var(--themecolor),1);
    border-color: rgba(var(--themecolor),1);
  }
  .start .el-button--primary,.start .el-button--primary:focus{
    background: rgba(var(--themecolor),0.75);
    border-color: rgba(var(--themecolor),0.75);
  }
  </style>