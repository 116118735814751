<template>
  <div class="app-container test-content">


    <div v-if="tipsFlag" class="tips-box">
      <tips-one :question-number="questionNumber" @change="handleChange"></tips-one>

    </div>


    <div v-else>
      <h4 class="title" v-if="$route.query.testType === '1'">MBTI职业性格测评</h4>
      <h4 class="title" v-else-if="$route.query.testType === '2'">性格与选科选专业</h4>
      <h4 class="title" v-else-if="$route.query.testType === '3'">中学生人格测评</h4>
      <div class="main">
        <div v-if="!testEnd">
          <div v-if="($route.query.testType === '1') || ($route.query.testType === '2')">
            <div class="personality">
              第{{ questionItem.Sort }}题，共{{ questionMain.length }}题
            </div>
            <div class="list-title" v-if="questionItem.Question">
              {{ questionItem.Sort }}、{{ questionItem.Question }}
            </div>
            <div class="list-item">
              <el-radio-group v-model="answer" @change="nextQuestion">
                <div class="answer-item" v-for="(item, index) in questionItem.AnswerList" :key="index">
                  <el-radio-button :label="item.AnswerValue">{{ item.AnswerItem }}
                  </el-radio-button>
                </div>
              </el-radio-group>
            </div>
          </div>
          <div v-else-if="$route.query.testType === '3'">
            <div class="list-title personality">
              第{{ questionItem.Sort }}题，共{{ questionMain.length }}题
            </div>
            <div class="list-item">
              <el-radio-group v-model="answer" @change="nextQuestion">
                <div class="answer-item">
                  <div class="answer-item">
                    <el-radio-button :label="questionItem.Code1">{{ questionItem.Question1 }}</el-radio-button>
                  </div>
                  <div class="answer-item">
                    <el-radio-button :label="questionItem.Code2">{{ questionItem.Question2 }}</el-radio-button>
                  </div>
                </div>
              </el-radio-group>
            </div>
          </div>
          <div style="text-align: center; color: rgb(54, 134, 226); font-size: 16px;" v-else>
            暂无数据...
          </div>
        </div>

        <div class="submit-answer" v-else>
          <el-button type="primary" @click="submitAnswer">提交测评</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/config'

import { getStore } from '@/utils/utils'
import TipsOne from '@/views/test/custom-pages/TipsOne'

export default {
  components: {
    TipsOne,
  },
  name: "test-content",
  data() {
    return {
      answer: null,
      AnswerList: [],
      questionItem: {},
      questionMain: [],

      testEnd: false,
      testResultID: '',

      questionIndex: 0,

      tipsFlag: true,
      questionNumber: 0
    }
  },
  computed: {},
  watch: {
    $route: function () {

      this.init()
    }
  },
  created() {

  },
  mounted() {

    this.init()
  },
  methods: {
    //提交测评结果
    submitAnswer() {
      this.$ls.set("testAnswer", this.AnswerList);

      let temp = getStore('LoginData')
      if (!temp) {
        this.$message.info('请先登录！')
        return
      }
      if (this.$route.query.testType * 1 === 1) {
        API.Career.MBTITest({
          UserId: JSON.parse(temp).UserId,
          AnswerList: this.AnswerList,
        }).then(res => {
        }).catch(err => {
          this.myAlert(err)
        })
        this.$router.push('/test-result?testType=1')
      } else if (this.$route.query.testType * 1 === 2) {
        API.Career.HollandTest({
          UserId: JSON.parse(temp).UserId,
          AnswerList: this.AnswerList,
        }).then(res => {
        }).catch(err => {
          this.myAlert(err)
        })
        this.$router.push('/test-result?testType=2')
      } else if (this.$route.query.testType * 1 === 3) {
        API.Career.PersonalityTest({
          UserId: JSON.parse(temp).UserId,
          AnswerList: this.AnswerList,
        }).then(res => {
        }).catch(err => {
          this.myAlert(err)
        })
        this.$router.push('/test-result?testType=3')
      }
    },
    //选择答案后进入下一题
    nextQuestion(val) {
      if (this.questionIndex >= this.questionMain.length - 1) {
        this.AnswerList.push(val)
        this.testEnd = true

      } else {
        this.AnswerList.push(val)
        setTimeout(() => {
          //下一题
          this.answer = null
          this.questionIndex++
          this.questionItem = this.questionMain[this.questionIndex]
        }, 200)
      }
    },


    // 
    handleChange(query) {
      if (query) {
        this.tipsFlag = false
      }
    },

    //初始化问题
    init() {
      this.questionIndex = 0
      this.AnswerList = []
      let type = this.$route.query.testType

      // loading
      let loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0,0,0,0.5)'
      })

      if (type * 1 === 1) {
        API.Career.MBTIQuestionList().then(res => {
          //MBTI
          this.questionNumber = res.data.length

          this.questionMain = res.data
          //显示列表中的第一个问题MBTI
          this.questionItem = this.questionMain[this.questionIndex]

          loading.close()
        }).catch(err => {

          this.myAlert(err)
          loading.close()
        })
      } else if (type * 1 === 2) {
        API.Career.HollandQuestionList().then(res => {
          //霍兰德
          this.questionNumber = res.data.length

          this.questionMain = res.data
          this.questionItem = this.questionMain[this.questionIndex]

          loading.close()
        }).catch(err => {

          this.myAlert(err)
          loading.close()
        })
      } else if (type * 1 === 3) {
        API.Career.PersonalityQuestionList().then(res => {
          //中学生人格测试
          this.questionNumber = res.data.length

          this.questionMain = res.data
          this.questionItem = this.questionMain[this.questionIndex]

          loading.close()
        }).catch(err => {

          this.myAlert(err)
          loading.close()
        })
      }
    },
    myAlert(params) {
      this.$message({
        type: 'error',
        message: params.message,
        showClose: true,
        center: true
      })
    }
  }
}
</script>

<style scoped lang="less">
.test-content {
  padding: 2rem 0;
  user-select: none;

  .title {
    text-align: center;
    font-size: 24px;
    color: rgba(var(--themecolor),0.75);
  }

  .main {
    border: 1px solid #e0e0e0;
    padding: 3rem;

    .list-title {
      padding: 1rem;
      font-size: 16px;
      font-weight: 600;
    }

    .personality {
      font-size: 18px;
      color: #999999;
    }

    .list-item {
      padding: 1rem;

      .answer-item {
        padding-bottom: 1rem;
      }
    }

    .submit-answer {}

  }
}
</style>
